import React, { useState, useEffect } from "react"
import Layout from "../../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../../components/modal"
import Student from "../../components/students/student-collab"
import SyllabusForm from "../../components/forms/syllabusForm"
import { Container, Col, Row } from "react-bootstrap"
import MattBig from "../../assets/images/matt-big.png"
import Navbar from "../../components/navbars/studentResourcesNavbar"
import Button from "../../components/buttons/linkButton"

const CourseContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-image: url(${MattBig});
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 250px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
    .cta-syllabus {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
`

const StudentsContainer = styled.section`
  background-color: var(--yellow);

  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const StudentsSection = styled.section`
  background-color: var(--yellow);

  hr {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 40px;
    text-align: center;
    font-weight: bold;
    color: var(--white);
  }

  .test-container {
    border: 5px solid var(--black);
    margin: 20px;
    background-color: #fff;
    box-shadow: -15px 15px var(--black);
    position: relative;
  }
  .test-text {
    padding: 0px 20px 0px 20px;
    margin: 0;
    font-size: 0.875em;
  }
  .preferences {
    text-align: left;
    padding: 0px 20px 20px 20px;
    margin: 0;
    font-size: 0.875em;
  }
  .test-img-box {
    margin-top: 10px;

    img {
      display: block;
      max-width: 250px;
      max-height: 150px;
      margin: 0 auto;
    }
  }
  .test-info-container {
    padding: 0px 20px 0px 20px;
  }
  .test-name {
    font-size: 0.875em;
    margin: 0;
  }
  .test-company {
    img {
      width: 100%;
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
  }
  .show-more-button {
    border-top: 1px solid var(--black);
    &:hover {
      background-color: var(--blue);
      cursor: pointer;

      p {
        color: var(--white);
      }
    }

    p {
      margin: 0;
      padding: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .test-padding-top {
      margin-top: 100px;
    }
  }
`

const FormsContainer = styled.section`
  padding-bottom: 80px;

  .form-button-container {
    text-align: center;
  }

  p {
    padding-top: 20px;
    text-align: center;
  }
  .forms-title {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
`

const ResourcesContainer = styled.section`
  background-color: var(--grey);
  padding-bottom: 80px;

  .form-button-container {
    text-align: center;
  }

  p {
    padding-top: 20px;
    text-align: center;
  }
  .forms-title {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
`

const Index = ({ location }) => {
  console.log(location)
  const [students, updateStudents] = useState([])

  useEffect(() => {
    // figure this out later: https://juliangaramendy.dev/blog/use-promise-subscription
    let isSubscribed = true

    fetch("https://student-profile-server.onrender.com/students/7")
      .then(response => response.json())
      .then(students => {
        console.log(students)
        if (isSubscribed) {
          updateStudents(students)
        }
      })

    return () => (isSubscribed = false)
  }, [])

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const renderStudents = () => {
    if (students.length > 0) {
      return students.map(function (s) {
        return <Student student={s} key={s._id} />
      })
    } else {
      return <h3>Loading students... This might take a minute!</h3>
    }
  }

  return (
    <Layout>
      <CourseContainer>
        <Navbar cohort={7} location={location} />
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className="col-hero-text text-center" md={12}>
              <h1>Student Resources_</h1>
            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer>
      <StudentsContainer>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="features-title" id="students">
                Cohort 7 Students
              </h2>
              <StudentsSection>
                <Container>
                  <Row>{renderStudents()}</Row>
                </Container>
              </StudentsSection>
            </Col>
          </Row>
        </Container>
      </StudentsContainer>
      <FormsContainer>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="forms-title" id="forms">
                Forms & Links
              </h2>
              <Container>
                <Row>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/c3GnOJNY">
                        <Button
                          link={true}
                          text={"Career Development Item Submission"}
                        />
                      </a>
                    </div>
                    <p>
                      Use this form to submit Career Development checkpoints.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://zoom.us/j/2690045149">
                        <Button link={true} text={"Open Zoom Room"} />
                      </a>
                    </div>
                    <p>
                      This Zoom room is open to anyone! Come hang out and
                      collaborate at any time!
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/zbne6z9D">
                        <Button link={true} text={"Weekly Feedback Form"} />
                      </a>
                    </div>
                    <p>
                      Please fill out this form on each Friday for Full-time
                      students and every other Friday for Part-time students.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/lYDCeJ43">
                        <Button link={true} text={"Eval Submission Form"} />
                      </a>
                    </div>
                    <p>Fill out this form each time you submit an eval.</p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/BEezXBmS">
                        <Button link={true} text={"Anonymous Feedback Form"} />
                      </a>
                    </div>
                    <p>
                      This form is always available to you if, for whatever
                      reason, you do not feel comfortable sharing your identity.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/Ynlf6Ukq">
                        <Button link={true} text={"Mentor Feedback Form"} />
                      </a>
                    </div>
                    <p>
                      At any point, you can fill out this form to let us know
                      how things are going with your mentor. You may fill it out
                      as many times as you’d like and on the form you can let us
                      know if you would like us to share any of this with your
                      mentor or not.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/EOdcfvJT">
                        <Button link={true} text={"Mentor Change Form"} />
                      </a>
                    </div>
                    <p>
                      Please fill out this form to request a different mentor.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/g3vGWCXO">
                        <Button
                          link={true}
                          text={"Notification of Mentor Missed Meeting Form"}
                        />
                      </a>
                    </div>
                    <p>
                      Use this form if your mentor missed your meeting with no
                      notice or explanation.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/hVSS12Cx">
                        <Button
                          link={true}
                          text={"Notification of Student Conflict Form"}
                        />
                      </a>
                    </div>
                    <p>
                      If there is an incident or if you have a conflict with
                      another student that Parsity should know about.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/LlndijhB">
                        <Button
                          link={true}
                          text={"Curriculum Error Notification"}
                        />
                      </a>
                    </div>
                    <p>If you spot a typo or error in the curriculum!</p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/eveQoiNE">
                        <Button
                          link={true}
                          text={"Job Offer Acceptance Form"}
                        />
                      </a>
                    </div>
                    <p>
                      Fill this out once you accept an offer! This is how we
                      gather our CIRR statistics.
                    </p>
                  </Col>
                  <Col md={4}>
                    <div className="form-button-container">
                      <a href="https://parsity.typeform.com/to/xvLkqskn">
                        <Button
                          link={true}
                          text={"Student Collaboration Form"}
                        />
                      </a>
                    </div>
                    <p>
                      If your schedule or anything changers, fill it out again!
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </FormsContainer>
    </Layout>
  )
}

export default Index
